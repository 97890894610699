<template>
  <div
    class="layout-container mb-48 md:mb-64 md:min-h-[400px]"
  >
    <div class="mb-16 sm:mb-24">
      <WysiwygWrapper
        v-if="model.description"
        :style-config="{
          'wysiwyg wysiwyg--sm wysiwyg--sm-text': true,
        }"
        :html="model.description"
      />
    </div>
    <div
      v-if="!hasLoadedCheckLoginStatus"
      class="relative h-auto min-h-208"
    >
      <img
        :src="'/lottie-animations/spinner-svg.svg?url'"
        :height="20"
        :width="20"
        class="absolute top-1/2 left-1/2 transform -translate-x-1/2 -translate-y-1/2"
      >
    </div>
    <div v-else>
      <div v-if="!isIdentifiedContact || (!favourites || favourites.length < 1)">
        <div class="flex justify-center items-center flex-col flex-1 mt-8 md:mt-12 border-2 border-border border-dashed rounded-md min-h-160 py-64 md:py-80 px-24">
          <img
            :src="'/heroicons/outline/bookmark.svg?url'"
            alt="Bookmark"
            class="h-40 w-40 mb-16"
            height="40"
            width="40"
          >
          <WysiwygWrapper
            v-if="model.emptyFavouritesListCta"
            :style-config="{
              'wysiwyg wysiwyg--sm wysiwyg--sm-text': true,
            }"
            :html="model.emptyFavouritesListCta"
          />
        </div>
      </div>
      <div v-else-if="favourites && favourites.length > 0">
        <div>
          <div class="flex flex-wrap -mx-12 sm:-mx-12">
            <div
              v-for="(product, index) in products"
              :key="index"
              class="w-1/2 sm:w-1/4 transition-all duration-200 px-12 pb-24 mb-12 opacity-0"
              :class="{ 'opacity-100': isAddedToFavourites(product.partNo)}"
            >
              <ProductSpot
                v-if="product"
                :product="product"
                :show-excerpt="false"
                :show-quickbuy="true"
                :small-layout="false"
                :slim-layout="false"
                :large="false"
                @remove-favourite="removeFromFavourites"
              />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { FavouritesBlock } from '~/content-types';
import { useGlobalContentStore } from '~/store/globalContent';
import ProductSpot from '~/components/product/ProductSpot.vue';
import type { IProductsResult, IProduct } from '~/api-types';
import { type Ref, ref } from 'vue';
import { storeToRefs } from 'pinia';
import { useUiStore } from '~/store/ui';
import { useFavouritesStore } from '~/store/favourites';
const globalContentStore = useGlobalContentStore();
const marketSettings = useGlobalContentStore().marketSettings;
const partNo = ref('');
const products: Ref<IProduct | undefined> = ref();
const loading = ref(true);
const favourites: Ref<string[] | undefined> = ref();
const uiStore = useUiStore();
const { isIdentifiedContact, hasLoadedCheckLoginStatus } = storeToRefs(uiStore);
const favouritesStore = useFavouritesStore();
const { updateFavourites } = storeToRefs(favouritesStore);
const { apiGet } = useApiFetch();

defineProps<{
  model: FavouritesBlock;
}>();

const fetchProducts = (async(partNoArray: string) => {
  const query = `products?countryCode=${marketSettings.countryCode}&${partNoArray}`;
  const res = await apiGet<IProductsResult>(query);
  const addedOrderArray = favourites.value?.map((item) => {
    return { partNo: item };
  });
  products.value = mapOrder(res.products, addedOrderArray, 'partNo');
  loading.value = false;
});

const buildFavouritesString = (async() => {
  partNo.value = '';
  if (favourites.value && favourites.value.length > 0) {
    favourites.value.forEach((item) => {
      partNo.value += `&PartNumbers=${item}`;
    });

    fetchProducts(partNo.value);
  }
});

const fetchFavourites = (async() => {
  const query = `user/favourites?CountryCode=${globalContentStore.marketSettings.countryCode}`;
  favourites.value = await apiGet<string[]>(query);
});

await fetchFavourites();

const removeFromFavourites = (async(partNo: string) => {
  const index = favourites.value?.indexOf(partNo) || -1;
  if (index !== -1) {
    favourites.value?.splice(index, 1);
  }
});

const isAddedToFavourites = ((partNo: string) => {
  return favourites.value && favourites.value.includes(partNo);
});

const mapOrder = (array, order, key) => {
  array.sort((a, b) => {
    const A = a[key];
    const B = b[key];
    const indexA = order.map((e) => { return e.partNo; }).indexOf(A);
    const indexB = order.map((e) => { return e.partNo; }).indexOf(B);
    return indexA > indexB ? 1 : -1;
  });
  return array;
};

if (hasLoadedCheckLoginStatus.value) {
  buildFavouritesString();
}

watch(updateFavourites, async(newVal) => {
  if (newVal) {
    await fetchFavourites();
    await buildFavouritesString();
  }
});

</script>
